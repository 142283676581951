import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  MdClose,
  MdOutlineArrowDropDown,
  MdOutlineDashboard,
} from "react-icons/md";
import { MdMenu } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import icons from "../../hooks/useIcons";
import { warningAlert } from "../../helpers/Swal";

function GenericModules() {
  const token = localStorage.getItem('token')
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `${token}`,
  }
  const [modulesData, setModulesData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      sourceCode: "",
      parentMenu: "",
      exhibition: {
        workspace: false,
        home: false,
      },
      icon: "",
    },
  });
  const [isCreate, setIscreate] = useState();
  const [isDropDownOpen, setIsDropDownOpen] = useState();
  const icon = watch("icon");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/Admin/genericmodule`;

  function onSubmit() {
    const body = getValues();

    const options = {
      headers,
      method: selectedData.id ? "PUT" : "POST",
      body: JSON.stringify([body]),
    };

    fetch(url, options)
      .catch((err) => {
        console.error(err);
        toast.error("Erro!");
      })
      .finally((res) => {
        reset({
          title: "",
          sourceCode: "",
          parentMenu: "",
          exhibition: {
            workspace: false,
            home: false,
          },
          icon: "",
        });
        toast.success("Módulo salvo com sucesso!");
        setIscreate(false);
        getModulesData();
        setSelectedData(null);
      });
  }

  function createData() {
    setIscreate(true);
    const newData = {
      title: "Novo Módulo",
      sourceCode: "",
      parentMenu: "",
      exhibition: {
        workspace: false,
        home: false,
      },
      icon: "home",
    };
    reset(newData);
    if (modulesData.some((x) => !x.id)) {
      setSelectedData(modulesData.find((x) => !x.id));
      return;
    }
    modulesData.push(newData);
    setSelectedData(newData);
  }

  async function deleteData(id) {
    const { isConfirmed } = await warningAlert.fire({
      title: `Tem certeza que deseja remover este módulo?`,
    });

    if (!isConfirmed) return;
    fetch(url, {
      headers,
      method: "DELETE",
      body: JSON.stringify([id]),
    })
      .catch((err) => {
        console.error(err);
        toast.error("Erro!");
      })
      .then(() => {
        toast.success("Módulo salvo com sucesso!");
      })
      .finally((res) => {
        getModulesData();
        reset({
          title: "",
          sourceCode: "",
          parentMenu: "",
          exhibition: {
            workspace: false,
            home: false,
          },
          icon: "",
        });
        setSelectedData(null);
      });
  }

  async function getModulesData() {

    await fetch(url, { headers }).then((r) =>
      r.json().then((items) => {
        setModulesData(items);
      })
    );
  }

  function undo() {
    if (selectedData.id) {
      reset(selectedData);
      return;
    }
    modulesData.pop();
    reset({
      title: "",
      sourceCode: "",
      parentMenu: "",
      exhibition: {
        workspace: false,
        home: false,
      },
      icon: "",
    });
    setIscreate(false);
    setSelectedData(null);
  }

  useEffect(() => {
    getModulesData();
  }, []);

  return (
    <div className="bg-white p-8 h-screen">
      <div className="flex justify-between">
        <div>
          <h1 className="font-semibold uppercase text-base">Administração</h1>
          <h1 className="text-4xl font-bold text-primary ">
            Módulos Genéricos
          </h1>
        </div>
        <button
          onClick={() => {
            createData();
          }}
          type="submit"
          className="text-secondary btn btn-primary h-12"
        >
          NOVO MÓDULO
        </button>
      </div>
      <div className="grid gap-8 grid-cols-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="col-span-2 flex mt-3 flex-col justify-center h-full"
        >
          <h1 className="text-lg font-bold mb-2">Propriedades</h1>

          <label>Titulo</label>
          <div className="relative">
            <input
              {...register("title", { required: true })}
              className="input"
              disabled={!selectedData}
            />
            <div
              className="rounded-lg cursor-pointer hover:bg-gray-300 absolute right-2 text-2xl top-0 mt-2.5"
              onClick={() => {
                reset({ title: "" });
              }}
            >
              <MdClose className="text-primary " />
            </div>
          </div>

          <label>Código</label>
          <div className="relative">
            <textarea
              {...register("sourceCode", { required: true })}
              className="input my-3"
              disabled={!selectedData}
            />
            <div
              className="rounded-lg cursor-pointer hover:bg-gray-300 absolute right-2 text-2xl top-0 mt-8"
              onClick={() => {
                reset({ sourceCode: "" });
              }}
            >
              <MdClose className="text-primary " />
            </div>
          </div>

          <label>Menu Parente</label>
          <div className="relative">
            <select
              {...register("parentMenu", { required: true })}
              className="input uppercase text-primary my-3 cursor-pointer font-semibold"
              disabled={!selectedData}
            >
              <option value={""} disabled className="uppercase text-primary">
                selecione
              </option>
              <option value={"Acompanhar"} className="uppercase text-primary">
                acompanhar
              </option>
              <option value={"Analisar"} className="uppercase text-primary">
                analisar
              </option>
            </select>
            <MdOutlineArrowDropDown className="text-primary absolute right-2 text-2xl top-0 mt-5 " />
          </div>

          <label>Exibição</label>
          <div className="flex items-center space-x-20 my-3">
            <div className="flex items-center my-3">
              <input
                {...register("exhibition.home")}
                type="checkbox"
                className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded"
                disabled={!selectedData}
              />
              <label className="ml-2 text-sm font-medium">MENU</label>
            </div>
            <div className="flex items-center my-3">
              <input
                {...register("exhibition.workspace")}
                type="checkbox"
                className="w-5 h-5 text-primary bg-gray-100 border-primary rounded"
                disabled={!selectedData}
              />
              <label className="ml-2 text-sm font-medium">WORKSPACE</label>
            </div>
          </div>


          <label>Ícone</label>
          <div className="relative">
            <div
              {...register("icon", { required: true })}
              className="text-gray-800 relative cursor-pointer h-12 w-1/3 input material-icons"
              onClick={() => {
                if (!selectedData) return;
                setIsDropDownOpen(!isDropDownOpen);
              }}
            >
              {icon}
              <MdOutlineArrowDropDown className="text-primary absolute right-2 text-2xl top-0 mt-3" />
            </div>
            {isDropDownOpen && (
              <div className="grid grid-cols-5 w-72 h-40  p-1 bg-gray-100 rounded-md overflow-y-scroll absolute z-50">
                {icons.map((icon, i) => {
                  return (
                    <div
                      key={icon + i}
                      className="col-span-1 rounded-full text-gray-800 w-10 h-10 p-2 text-center cursor-pointer hover:bg-gray-300"
                      onClick={() => {
                        setValue("icon", icon);
                        setIsDropDownOpen(!isDropDownOpen);
                      }}
                    >
                      <span className="material-icons">{icon}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="flex space-x-3 mt-3 justify-between">
            <button
              type="submit"
              className={`text-secondary flex-grow btn btn-primary ${!isValid && "opacity-30 hover:opacity-30"
                }`}
              disabled={!isValid || !selectedData}
            >
              Salvar Alterações
            </button>
            <button
              onClick={() => {
                undo();
              }}
              type="button"
              className={`text-primary flex-grow btn btn-secondary ${!selectedData && "opacity-30 hover:opacity-30"
                }`}
              disabled={!selectedData}
            >
              {isCreate ? "Cancelar" : "Desfazer"}
            </button>
          </div>
        </form>

        <div className="col-span-3 mt-5">
          {modulesData.map((item, i) => {
            return (
              <div
                key={item.id + item.title}
                onClick={(e) => {
                  e.preventDefault();
                  reset(item);
                  setSelectedData(item);
                }}
                className="relative h-16 flex items-center bg-white border-2 border-gray transition hover:bg-gray-200 cursor-pointer my-2"
              >
                <div
                  className={`h-full w-3 ${item === selectedData && "bg-primary"
                    }`}
                ></div>
                <div className="p-3 flex flex-col">
                  <button
                    className={`text-xl p-1 ${item.exhibition.home ? "text-primary" : "text-gray-300"
                      }`}
                  >
                    <MdMenu />
                  </button>
                  <button
                    className={`text-xl p-1 ${item.exhibition.workspace
                      ? "text-primary"
                      : "text-gray-300"
                      }`}
                  >
                    <MdOutlineDashboard />
                  </button>
                </div>
                <div className="rounded-full text-secondary h-12 p-3 bg-primary">
                  <span className="material-icons">{item.icon}</span>
                </div>
                <div className="p-3 flex flex-col">
                  <h1 className="text-base font-bold">{item.title}</h1>
                  <span>{item.parentMenu}</span>
                </div>
                {item.id && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteData(item.id);
                    }}
                    className="right-2 absolute rounded-full text-primary h-10 p-2 hover:bg-gray-400"
                  >
                    <span className="material-symbols-outlined">
                      delete
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default GenericModules;
