import { BASE_URL, getApiHeaders } from "./settings";

const URL = "Admin";

const Api = {
    fetchSignatureModules: async function (data = {}) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/packages`, {
                headers: getApiHeaders()
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    postPackages: async function (params) {
        // try {
            const response = await fetch(`${BASE_URL}/${URL}/packages`, {
                headers: getApiHeaders(), method: "PUT", body: JSON.stringify(params)
            });
            if (response.status < 200 || response.status >= 300) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            if (response.status === 204) {
              return
            }
            return response.json();
        // } catch (error) {
        //     console.error(error);
        //     return null;
        // }
    },
};

export default Api;