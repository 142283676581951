import { useSignIn } from "react-auth-kit"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import Api from "../../api/Auth"
import { toast } from "react-toastify"

function Login() {
  const { register, handleSubmit } = useForm()
  const signIn = useSignIn()
  const navigate = useNavigate()

  async function onSubmit(user) {
    await Api.login(user).then(x => {
      if (x.isAuthorized) {
        localStorage.setItem('token', x.token)
        signIn({
          token: x.token,
          expiresIn: 50000,
          authState: { username: user.username },
        })
        navigate("/")
      } else {
        toast.error('Usuário ou senha inválidos!')
      }
    })
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-white shadow w-96 h-96 rounded-lg p-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center h-full"
        >
          <h1 className="text-center text-2xl mb-5 font-bold text-black">
            Portal Admin
          </h1>
          <label>Username</label>
          <input
            {...register("email", { required: true })}
            className="input"
          />
          <label>Password</label>
          <input
            {...register("password", { required: true })}
            type="password"
            className="input"
          />
          <div className="space-x-5 flex justify-between mt-2">
            <button type="submit" className="flex-grow btn btn-primary">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
