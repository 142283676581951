import { Link } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import Logo from "../../assets/logo-valor-one-beta.svg";

function NotFound() {
  useTitle("Oooops... Página não encontrada");

  return (
    <section className="flex flex-col items-center justify-center space-y-10 h-screen">
      <Link
        to={`/`}
        className="min-w-[200px]"
      >
        <img src={Logo} alt="Valor One" className="h-16" />
      </Link>
      <h1 className="text-5xl text-center mb-4 text-gray-700">
        404 | Página não encontrada
      </h1>
      <div className="text-center">
        <Link
          to={`/`}
          className="link font-bold"
        >
          Voltar para página inicial
        </Link>
      </div>
    </section>
  );
}

export default NotFound;
