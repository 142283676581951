import { BASE_URL, getApiHeaders } from "./settings";

const URL = "Preferences";

const Api = {
    fetchActives: async function (data = {}) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/panelquotationdefault`, {
                headers: getApiHeaders()
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    postActives: async function (params) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/panelquotationdefault`, {
                headers: getApiHeaders(), method: "POST", body: JSON.stringify(params)
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    putActives: async function (params) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/panelquotationdefault`, {
                headers: getApiHeaders(), method: "PUT", body: JSON.stringify(params)
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    reorderItems: async function (params) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/panelorderquotationdefault`, {
                headers: getApiHeaders(), method: "PUT", body: JSON.stringify(params)
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    deleteActives: async function (params) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/panelquotationdefault`, {
                headers: getApiHeaders(), method: "DELETE", body: JSON.stringify(params)
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
};

export default Api;