import { Route, Routes } from "react-router-dom";
import Header from "../layout/Header";
import Home from "../pages/Home";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import { RequireAuth } from "react-auth-kit";
import Packages from "../pages/AccessControl";
import GenericModules from "../pages/GenericModules";
import ActivesList from "../pages/Assets";

function RoutesApp() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/modulos"
        element={
          <RequireAuth loginPath="/login">
            <Header />
            <GenericModules />
          </RequireAuth>
        }
      />
      <Route
        path="/conteudo"
        element={
          <RequireAuth loginPath="/login">
            <Header />
            <Packages />
          </RequireAuth>
        }
      />
      <Route
        path="/ativos"
        element={
          <RequireAuth loginPath="/login">
            <Header />
            <ActivesList />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth loginPath="/login">
            <Header />
            <Home />
          </RequireAuth>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RoutesApp;
