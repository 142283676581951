import Logo from "../assets/logo-valor-one-beta.svg";
import { Link } from "react-router-dom";
import { useSignOut } from "react-auth-kit";
import useMenu from "../hooks/useMenu";

function Header() {

  // const width = useWindowSize();

  // const navigate = useNavigate();

  return (
    <>
      <header className="fixed w-full px-5 z-10 flex items-center bg-white shadow-md justify-between h-20 min-h-20">
        <Link to="/" className="min-w-[150px]">
          <img src={Logo} alt="Valor One" className="h-12" />
        </Link>
        <MenuNav />
      </header>
      <div className="relative h-20"></div>
    </>
  );
}

function MenuNav() {
  const { menuItems } = useMenu();

  return (
    <div className="hidden lg:flex space-x-5 items-center flex-grow justify-end">
      <ul className="flex list-none space-x-4">
        {menuItems.map((item) => (
          <MenuItem key={"menu-item-" + item.name} item={item}></MenuItem>
        ))}
      </ul>
    </div>
  );
}

function MenuItem({ item }) {
  const circleColor =
    item.type === "regular"
      ? "bg-primary text-secondary"
      : "text-primary bg-secondary";

  const signOut = useSignOut();

  const logout = () => {
    localStorage.removeItem('token')
    signOut();
  };

  return (
    <div className="flex flex-col items-center w-[55px] space-y-1 relative menu-item-desktop">
      <Link
        to={item.url}
        className={`
          rounded-full 
          ${circleColor}
          h-[35px]
          w-[35px]
          cursor-pointer
          transition
          ease-in
          flex
          justify-center
          items-center
          hover:scale-110
        `}
      >
        {item.ico}
      </Link>
      {item.subitems && (
        <div className="shadow-sm fixed bg-white z-50 py-2 top-12 border-gray-200 border rounded-lg w-[140px] flex menu-item-desktop-options">
          <div className="py-2 space-y-1">
            <p className="text-primary uppercase text-xs font-bold text-center">
              {item.name}
            </p>
          </div>
          {item.subitems &&
            item.subitems.map((subitem, index) => (
              <Link
                key={`menu-item-${item.name}-${subitem.name}`}
                to={subitem.url}
                className="flex items-center hover:bg-gray-100 px-2 space-x-2 py-2"
                onClick={logout}
              >
                <p className="text-primary uppercase text-2xs font-bold">
                  {subitem.name}
                </p>
              </Link>
            ))}
        </div>
      )}

      <p className="text-primary uppercase text-2xs font-extrabold cursor-pointer">
        {item.name}
      </p>
    </div>
  );
}

export default Header;
