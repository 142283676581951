import { useCallback, useContext, useEffect, useState } from "react"
import { MdClose } from "react-icons/md"
import ActivesContext, { ActivesContextProvider } from "../../contexts/ActivesContext"
import { DragDropContext } from "react-beautiful-dnd"
import { toast } from "react-toastify"
import { Droppable } from "react-beautiful-dnd"
import { Draggable } from "react-beautiful-dnd"
import QuoteApi from "../../api/QuoteApi"
import Api from "../../api/ActivesApi"
import { warningAlert } from "../../helpers/Swal"
import { useForm } from "react-hook-form"


function AssetsList() {

  return (
    <>
      <ActivesContextProvider>
        <div className="bg-white p-8 h-screen" >
          <div className="flex justify-between">
            <div>
              <h1
                className="font-semibold uppercase text-base">Administração</h1>
              <h1 className="text-4xl font-bold text-primary">Listas de Ativos</h1>
            </div>
          </div>

          <div className="grid grid-cols-12 mt-5 gap-16">
            <PanelList />
            <ActiveList />
          </div>
        </div >
      </ActivesContextProvider>
    </>

  )
}

function PanelList({ data, setData }) {

  const [newList, setNewList] = useState('')
  const { panelList, onSetPanelList, onSelectActive } = useContext(ActivesContext)

  const getData = useCallback(async () => {
    try {
      const items = await Api.fetchActives()
      const filteredItems = items.filter(x => x.type !== "WALLET_LIST").sort((x, y) => x.order > y.order ? 1 : -1)
      onSetPanelList(filteredItems)
      onSelectActive(filteredItems[0])
    } catch (error) {
      console.error(error)
    }
  }, [onSelectActive, onSetPanelList])

  async function createPanel() {
    if (!newList) return
    const body = [
      {
        name: newList,
        symbols: [],
        principal: true
      }
    ]
    try {
      await Api.postActives(body).then(x => {
        onSetPanelList([...panelList || [], ...x])
      })
      toast.success('Painel criado com sucesso!')
    } catch (error) {
      console.error('Ocorreu um erro ao criar o painel:', error)
      toast.error('Ocorreu um erro durante a operação')
    }
  }

  function updateData(param) {
    const arr = panelList.filter(x => {
      return x.id !== param
    })
    onSetPanelList(arr)
  }

  async function setItemsOrder(body) {
    // const body = { ...selectedActive, name: activeListName }
    try {
      await Api.reorderItems(body).then(x => {
        // const arr = [...panelList]
        // let index = arr.findIndex(obj => obj.id === x.id);

        // if (index !== -1) {
        //     arr[index] = x;
        //     onSetPanelList(arr)

        // }
      })
      toast.success('Alterações salvas com sucesso!')
    } catch (error) {
      toast.error('Ocorreu um erro durante a operação')
    }

  }

  useEffect(() => {
    getData()
  }, [getData,])

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = [...panelList]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    onSetPanelList(items)
    const reorderedItems = items.filter(x => x.type !== "WALLET_LIST").map((item, i) => {
      return { id: item.id, order: (i + 1) }
    })
    setItemsOrder(reorderedItems)
    // setData(items)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="col-span-4">
        <label>Nome da lista</label>
        <div className="flex space-x-3 mb-2">
          <input
            onChange={(e) => {
              setNewList(e.target.value)
            }}
            value={newList}
            className="input"
          />
          <button onClick={() => {
            createPanel()
            setNewList('')
          }} className="h-11 text-secondary btn btn-primary">
            Adicionar
          </button>
        </div>

        <div className="p-5 h-16 bg-gray-200 border-b-gray-500 border-b-2 rounded-sm align-bottom">
          <h1 className="text-2xl uppercase font-bold text-primary text-ellipsis whitespace-nowrap overflow-hidden">painéis sugeridos</h1>
        </div>
        <div className="overflow-y-scroll mini-scrollbar">
          {!panelList?.length ? <div className="mt-3 text-center text-xl">Essa lista ainda não possui painéis</div> :
            <Droppable droppableId="panelListItems">
              {(provided) => (
                <ul className="h-60" {...provided.droppableProps} ref={provided.innerRef}>
                  {panelList?.map((item, i) => {
                    return (

                      <Draggable key={item.id} draggableId={`${item.name}-${item.id}`} index={i}>
                        {(providedChild) => (
                          <li key={item.id} ref={providedChild.innerRef} {...providedChild.draggableProps} {...providedChild.dragHandleProps} >
                            <PanelListItem data={item} updateData={updateData} />
                          </li>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          }
        </div>
      </div>
    </DragDropContext>
  )
}

function PanelListItem({ data, ref, updateData }) {

  const { selectedActive, onSelectActive } = useContext(ActivesContext)

  async function deleteData(id) {
    const { isConfirmed } = await warningAlert.fire({
      title: `Tem certeza que deseja remover este painel?`,
    })
    if (!isConfirmed) return

    try {
      await Api.deleteActives([id]).then(x => {
        toast.success('Painel excluído com sucesso!')
      })
      updateData(id)
    } catch (error) {
      console.error('Ocorreu um erro ao criar o painel:', error)
      toast.error('Ocorreu um erro durante a operação')
    }
  }

  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        if (data === selectedActive) return
        onSelectActive(data)
      }}
      className="relative pl-1 h-10 flex items-center bg-white border-b-2 border-b-gray transition hover:bg-gray-100 cursor-grab"
    >
      <span className="p-3 material-icons text-primary">drag_indicator</span>
      <span className="p-3 text-base">{data.name}</span>
      <div
        onClick={(e) => {
          e.stopPropagation()
          deleteData(data.id)
          onSelectActive(null)
        }}
        className="right-4 absolute rounded-full text-primary h-8 p-1 hover:bg-gray-400 cursor-pointer"
      >
        <span className="material-symbols-outlined">
          delete
        </span>
      </div>
      <div className={`absolute right-0 h-full w-3 ${data?.id === selectedActive?.id && 'bg-primary'}`}></div>
    </div>
  )
}

function ActiveList({ updateList }) {

  const [isEditListName, setIsEditListName] = useState(false)
  const [activeListName, setActiveListName] = useState('')
  const { selectedActive, onSelectActive, initialState, onSetPanelList, panelList } = useContext(ActivesContext)
  const [activeListData, setActiveListData] = useState()
  const { register, getValues, reset } = useForm({})

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = [...selectedActive.symbols]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setActiveListData(items)
    const obj = { ...selectedActive, symbols: items }
    onSelectActive(obj)
    // console.log(items);
  }

  useEffect(() => {
    reset({
      principal: true,
      valuation: selectedActive?.valuation,
      rentability: selectedActive?.rentability,
    })
    console.log(selectedActive)
  }, [reset, selectedActive])

  function addSymbol(symbol) {
    const body = {
      symbol: symbol.symbolCode,
      origin: symbol.originId
    }
    if (activeListData.some(x => x.symbol === body.symbol)) {
      toast.warning('O ativo selecionado ja existe na lista!')
      return
    }
    const obj = { ...selectedActive, symbols: [...selectedActive.symbols, body] }
    onSelectActive(obj)
  }

  async function putAssetsList() {
    console.log(getValues())
    const reorderedActives = selectedActive.symbols.map((item, i) => {
      return { ...item, order: (i + 1) }
    })

    const body = { ...selectedActive, symbols: reorderedActives, name: activeListName, view: { principal: true, valuation: getValues().valuation, rentability: getValues().rentability } }
    console.log(body)

    try {
      await Api.putActives(body).then(x => {
        const arr = [...panelList]
        let index = arr.findIndex(obj => obj.id === x.id)

        if (index !== -1) {
          arr[index] = x
          onSetPanelList(arr)
        }
      })
      toast.success('Alterações salvas com sucesso!')
    } catch (error) {
      console.error('Ocorreu um erro ao criar o painel:', error)
      toast.error('Ocorreu um erro durante a operação')
    }
  }

  useEffect(() => {
    setActiveListData(selectedActive?.symbols)
    setActiveListName(selectedActive?.name)
  }, [activeListData, initialState, selectedActive])

  return <DragDropContext onDragEnd={handleOnDragEnd}>
    {
      !selectedActive ? <div className="col-span-8 text-center"></div> :
        <div className="col-span-8">
          {!isEditListName ?
            <>
              <div onClick={() => {
                setIsEditListName(true)
                setActiveListName(selectedActive.name)
              }} className="flex space-x-1 cursor-pointer mb-3">
                <h1 className="text-xl font-bold">{selectedActive.name}</h1>
                <span className="material-icons text-primary">edit</span>
              </div>
            </>
            :
            <>
              <div className="flex space-x-1">
                <div className="relative">
                  <input
                    className="input w-72"
                    value={activeListName}
                    onChange={(e) => { setActiveListName(e.target.value) }}
                  />
                  <div
                    className="rounded-lg cursor-pointer hover:bg-gray-300 absolute right-2 text-2xl top-0 mt-2.5"
                  >
                    <MdClose onClick={() => {
                      setActiveListName('')
                    }} className="text-primary " />
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (!activeListName) {
                      toast.error('Insira um nome válido para a lista!')
                      return
                    }
                    setIsEditListName(false)
                    selectedActive.name = activeListName
                  }}
                  className="right-4 rounded-full cursor-pointer text-primary h-10 p-2 hover:bg-gray-300"
                >
                  <span className="material-icons">check</span>
                </div>
              </div>
            </>
          }
          <AutoComplete selectItem={addSymbol} activeList={activeListData} />

          <div className="p-5 h-16 bg-gray-200 rounded-sm border-b-gray-500 border-b-2 align-bottom">
            <h1 className="text-2xl uppercase font-bold text-primary">ativo</h1>
          </div>

          <Droppable droppableId="ActiveListItems">
            {(provided) => (
              <ul className="h-60 overflow-y-scroll border-b-gray-300 border-b-[2px]" {...provided.droppableProps} ref={provided.innerRef}>
                {!activeListData?.length ? <div className="mt-3 text-center text-2xl">Essa lista ainda não possui ativos</div> :
                  activeListData?.map((item, i) => {
                    return (
                      <Draggable key={`${item.symbol}-${item.origin + i}`} draggableId={`${item.symbol}-${item.origin + i}`} index={i}>
                        {(providedChild) => (
                          <li key={item.id} ref={providedChild.innerRef} {...providedChild.draggableProps} {...providedChild.dragHandleProps} >
                            <ActiveListItem key={`${item.symbol}-${item.origin + i}`} data={item} />
                          </li>
                        )}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>

          <div className="w-full h-16 bg-gray-200 flex p-5 items-center border-b-gray-500 border-t-gray-500 border-b-2">
            <span className="font-bold text-center text-primary mr-7 mt-[2px]">VIEWS:</span>
            <div className="flex px-5 items-center">
              <input
                {...register('principal')}
                disabled={true}
                checked={true}
                type="checkbox"
                className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
              />
              <label className="font-semibold text-center ml-2">PRINCIPAL</label>
            </div>
            <div className="flex px-5 items-center">
              <input
                {...register('valuation')}
                type="checkbox"
                className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
              />
              <label className="font-semibold text-center ml-2">VALUATION</label>
            </div>
            <div className="flex px-5 items-center">
              <input
                {...register('rentability')}
                type="checkbox"
                className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
              />
              <label className="font-semibold text-center ml-2">RENTABILIDADE</label>
            </div>
          </div>

          <div className="justify-end flex space-x-3 mt-3">
            <button onClick={(e) => {
              e.preventDefault()
              putAssetsList()
            }}
              disabled={false} type="submit" className={`h-12 text-secondary btn btn-primary`}>
              Salvar Alterações
            </button>
            <button onClick={(e) => {
              e.preventDefault()
            }} className="h-12 text-primary btn btn-secondary">
              Desfazer
            </button>
          </div>
        </div>
    }
  </DragDropContext>
}

function ActiveListItem({ data, updateData }) {

  const { selectedActive, onSelectActive } = useContext(ActivesContext)

  function deleteData(data) {
    const i = selectedActive.symbols.findIndex((x) => {
      return x.symbol === data.symbol
    })
    const item = selectedActive.symbols.splice(i, 1)
    const arr = selectedActive.symbols.filter(x => {
      return x !== item
    })
    onSelectActive({ ...selectedActive, symbols: arr })
  }

  return (
    <div className="relative h-12 flex items-center bg-white border-b-2 border-gray"
    >

      <span className="p-3 material-icons text-primary">drag_indicator</span>
      <span className="p-3 text-base">{data.symbol}
      </span>
      <div
        onClick={(e) => {
          e.stopPropagation()
          deleteData(data)
        }}
        className="right-4 absolute cursor-pointer rounded-full text-primary h-8 p-1 hover:bg-gray-400"
      >
        <span className="material-symbols-outlined">
          delete
        </span>
      </div>
    </div>
  )
}

function AutoComplete({ selectItem, activeList }) {

  const [symbolsList, setSymbolsList] = useState('')
  const [symbolInputCode, setSymbolInputCode] = useState()

  function handlePress(e) {
  }

  async function updateSearchValue(searchValue) {
    setSymbolInputCode(searchValue)
    if (searchValue?.length < 1) {
      setSymbolsList(null)
      return
    }
    searchValue = searchValue.toUpperCase()
    const response = await QuoteApi.autocompleteSuggestion(searchValue)
    setSymbolsList(response || [])
  }

  function onSelectItem(data) {
    setSymbolInputCode('')
    setSymbolsList(null)
    selectItem(data)
  }

  return (
    <div className="relative my-1">
      <label>Códigos dos ativos separados por vírgula</label>
      <div className="flex space-x-3">
        <input
          className="input"
          value={symbolInputCode}
          onChange={(e) => { updateSearchValue(e.target.value.toUpperCase()) }}
          onKeyDown={handlePress}
          autoComplete="off"
          placeholder="Digite o nome ou código do ativo"
        />
      </div>
      <div className="bg-gray-200 rounded-md absolute z-50 w-full">
        {symbolsList && (
          <div className={`rounded bg-white w-full border top-full`}>
            {!symbolsList?.length ? <div className="p-2 text-gray-500 text-sm">Nenhum ativo encontrado...</div> : symbolsList.map((item, index) => (
              <div
                className={`p-2 hover:bg-gray-200 flex space-x-1 cursor-pointer font-semibold items-center`}
                key={'autocomplete_symbol_' + item.symbolCode}
                onClick={() => onSelectItem(item)}>
                <div className="flex-1 flex flex-wrap items-baseline">
                  <h3 className="text-base whitespace-nowrap mr-1">{item.symbolCode}</h3>
                  <p className="text-xs text-gray-500 flex-1 whitespace-nowrap">{item.securityDesc}</p>
                </div>
                <p className={`text-xs text-white rounded-md p-1 ${getOriginColor(item.originId)}`}>{item.origin}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export const getOriginColor = originId => {
  switch (originId) {
    case 1: return 'bg-secondary'
    case 2: return 'bg-primary'
    case 3: return 'bg-terciary'
    default: return 'bg-gray-500'
  }
}



export default AssetsList