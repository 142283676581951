import { Fragment, useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import Api from "../../api/PackagesApi";

const BUCKET_URL =
  "https://storage.googleapis.com/images-vo/assets/admin/permissions/";

const signaturesTypes = [
  { signature: "019423", label: "ONE Grátis" },
  { signature: "019425", label: "ONE Básico" },
  { signature: "019427", label: "ONE Intermediário" },
  { signature: "019429", label: "ONE Premium" },
];

function AccessControlList() {
  const fetchData = async () => {
    try {
      const items = await Api.fetchSignatureModules();
      const packagesData = items;
      const categories = formatCategoriesFromPackagesResponse(items);

      const formData = formatPackagesDataResponse(items);
      setState({ packagesData, categories, formData });
      // setInitialFormData(formData)
      // reset(formData);
      return formData;
    } catch (error) {
      console.error("Failed to fetch signature modules", error);
    }
  };

  const methods = useForm({ mode: "all", defaultValues: fetchData });
  const { reset, handleSubmit } = methods;
  const [state, setState] = useState({
    packagesData: [],
    categories: [],
    formData: null,
  });

  const onSubmit = async (data) => {
    const items = Object.entries(data).map(([key, value]) => ({
      id: +key,
      ...value,
    }));
    const newProps = items.map((x) => ({
      ...state.packagesData.find((y) => x.id === y.id),
      ...x,
    }));
    try {
      await Api.postPackages(newProps);
      setState((prevState) => ({ ...prevState, formData: data }));
      toast.success("Alterações salvas com sucesso!");
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível salvar suas alterações");
    }
  };

  const handleUndo = () => {
    reset(state.formData);
  };

  return (
    <div className="bg-white p-8">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between flex-col lg:flex-row">
            <div>
              <h2 className="font-semibold uppercase text-base">
                Administração
              </h2>
              <h1 className="text-4xl font-bold text-primary">Conteúdo</h1>
            </div>
            <ControlButtons undo={handleUndo} />
          </div>

          <div className="relative overflow-x-auto my-5">
            <table className="w-full text-sm text-left">
              <TableHeader />
              <TableRows categories={state.categories} />
            </table>
          </div>
          <ControlButtons undo={handleUndo} />
        </form>
      </FormProvider>
    </div>
  );
}

function ControlButtons({ undo }) {
  return (
    <div className="flex space-x-3 justify-end">
      <SaveButton />
      <UndoButton onClick={undo} />
    </div>
  );
}

function TableHeader() {
  return (
    <thead className="bg-gray-200 text-primary border-b-black border">
      <tr>
        <th scope="col" className="px-6 py-3"></th>
        {signaturesTypes.map((item) => (
          <th
            key={`${item.signature}-row`}
            scope="col"
            className="text-xl px-6 py-3"
          >
            {item.label}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function Checkbox({ item, planType }) {
  const { register, setValue, watch, getValues } = useFormContext();
  const [disabled, setDisabled] = useState(
    item.parentid && !getValues()[item.parentid][planType]
  );

  useEffect(() => {
    if (!item.parentid) {
      return;
    }
    const subscription = watch((value, props) => {
      if (props.name === `${item.parentid}.${planType}`) {
        setDisabled(!value[item.parentid][planType]);
      }
    });
    return () => subscription.unsubscribe();
  }, [item, planType, watch]);

  const handleUpdateSubitems = (checked) => {
    setValue(`${item.id}.${planType}`, checked);
    if (checked || item.length === 0) {
      return;
    }
    // Recursively load form
    const toUpdateKeys = [];
    (function getToUpdateObj(subitems) {
      for (const item of subitems) {
        toUpdateKeys.push(`${item.id}.${planType}`);
        getToUpdateObj(item.subitems);
      }
    })(item.subitems);
    for (const key of toUpdateKeys) {
      setValue(key, false);
    }
  };

  return (
    <td className="px-6 py-2">
      <input
        {...register(`${item.id}.${planType}`)}
        id={`${item.id}_${planType}_input`}
        onChange={(ev) => handleUpdateSubitems(ev.target.checked)}
        type="checkbox"
        disabled={disabled}
        className="w-5 h-5 text-secondary bg-white border-primary rounded align-middle checked:bg-primary checked:border-primary disabled:border-gray-300"
      />
    </td>
  );
}

function TableRows({ categories }) {
  return (
    <tbody>
      {categories.map(({ category, items }) => (
        <Fragment key={category}>
          <tr className="bg-white border-b dark:border-gray-400">
            <th
              scope="row"
              className="font-bold text-lg uppercase px-6 py-3 categories text-primary"
            >
              {category}
            </th>
          </tr>
          <TableRowItems category={category} items={items} />
        </Fragment>
      ))}
    </tbody>
  );
}

function TableRowItems({ category, items, level = 0 }) {
  return items.map((item) => (
    <Fragment key={`${category}_${item.id}`}>
      <tr className="bg-white border-b dark:border-gray-400">
        <th
          scope="row"
          className="px-6 py-2 font-medium text-base text-gray-900 whitespace-nowrap flex items-center space-x-2 md:max-w-[35vw]"
        >
          {level === 0 && item.icon && (
            <img alt={item.label} src={BUCKET_URL + item.icon} />
          )}
          <span
            style={{ paddingLeft: level * 20 }}
            className="overflow-hidden text-ellipsis whitespace-nowrap"
            title={item.label}
          >
            {level > 0 && <span className="mr-2">●</span>}
            {item.label}
          </span>
        </th>
        <Checkbox item={item} planType="gratis" />
        <Checkbox item={item} planType="basico" />
        <Checkbox item={item} planType="intermediario" />
        <Checkbox item={item} planType="avancado" />
      </tr>
      <TableRowItems
        category={`${category}_${item.category}`}
        items={item.subitems}
        level={level + 1}
      />
    </Fragment>
  ));
}

function SaveButton() {
  return (
    <button
      type="submit"
      className="h-12 text-secondary btn btn-primary text-sm lg:text-base"
    >
      Salvar Alterações
    </button>
  );
}

function UndoButton({ onClick }) {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <button
      onClick={handleClick}
      className="h-12 text-primary btn btn-secondary text-sm lg:text-base"
    >
      Desfazer
    </button>
  );
}

const formatPackagesDataResponse = (packagesResponse) => {
  const packagesData = packagesResponse.reduce((ac, pack) => {
    const { id, gratis, basico, avancado, intermediario } = pack;
    return {
      ...ac,
      [id]: { gratis, basico, intermediario, avancado },
    };
  }, {});
  return packagesData;
};

const formatCategoriesFromPackagesResponse = (packagesResponse) => {
  const handleSubitemsRecursively = (parent) => {
    const subitems = packagesResponse
      .filter((item) => parent.id === item.parentid)
      .map(handleSubitemsRecursively);

    return {
      ...parent,
      subitems,
    };
  };

  const categories = packagesResponse?.reduce((ac, item) => {
    if (!item.parentid && !ac.some((x) => x.category === item.category)) {
      ac.push({
        category: item.category,
        items: packagesResponse
          .filter((x) => x.category === item.category)
          .map(handleSubitemsRecursively),
      });
    }
    return ac;
  }, []);

  return categories;
};

export default AccessControlList;
